@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&family=Syne:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  overflow-x: hidden;
}

h1,
h2,
h3 {
  font-family: 'Roboto', sans-serif;
}

h2,
h3 {
  color: gray;
}

h1 {
  font-size: 3vw;
}

h3 {
  font-size: 2vw;
}

a {
  text-decoration: none;
}

hr {
  height: 1px;
  background-color: gray;
  border: none;
}

.homepage-grid {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 8vw;
}

.grid {
  padding: 10px;
  width: 80vw;
  height: 98vh;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 10px;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .box-one {
  grid-area: 1 / 1 / span 1 / span 1;
  background-color: white;
} */

.box-two {
  grid-area: 1 / 1 / span 10 / span 1;
  background-color: #ff4037;
  z-index: 1;
}

.box-three {
  grid-area: 1 / 2 / span 3 / span 4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #262d88;
}

.box-four {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 4 / 2 / span 4 / span 4;
  background-color: white;
  z-index: 1;
}
.box-five {
  grid-area: 1 / 6 / span 6 / span 7;
  background-color: lightcoral;
}

.box-six {
  /* display: grid;
  grid-template-columns: repeat(25, 1fr);
  grid-template-rows: 1fr; */
  grid-area: 7 / 6 / span 2 / span 2;
  z-index: 1;
}

.box-seven {
  grid-area: 7 / 8 / span 3 / span 5;
}

.box-eight {
  grid-area: 8 / 2 / span 2 / span 4;
}

.box-nine {
  background-color: #9668f1;
  grid-area: 10 / 2 / span 1 / span 11;
  z-index: 1;
}

.box-ten {
  background-color: #d6fc52;
  grid-area: 9 / 6 / span 1 / span 2;
  z-index: 1;
}

.box-eleven {
  background-color: white;
  grid-area: 11 / 8 / span 3 / span 5;
}

/* marquee text styles */

.vertical {
  -ms-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -ms-transform-origin: right top 0;
  -moz-transform-origin: right top 0;
  -webkit-transform-origin: right top 0;
  transform-origin: right top 0;
  right: 101.25%;
  color: white;
  text-transform: uppercase;
  cursor: default;
  width: 80%;
}

.horizontal {
  cursor: default;
  width: 100%;
}

.scroll {
  white-space: nowrap;
  position: absolute;
  display: block;
  direction: rtl;
  z-index: 11;
  overflow: hidden;
}

@keyframes moveDown {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1500vh);
  }
}

.animation {
  animation: 150s infinite moveDown;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.animation h1 {
  font-family: 'IBM Plex Sans', sans-serif;
  color: white;
  font-size: 4vw;
}

#lower-marquee {
  font-size: 4vw;
  transform: translateY(-20px);
  margin-bottom: 0;
}

#side-marquee {
  -webkit-text-fill-color: #ff4037; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: white;
}

.project-div {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.green {
  color: green;
}
